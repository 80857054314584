import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import { AddressMap } from "views/map";

function FooterPadrao(){
    return(
        <>
           <footer className="footer footer-big">
      <Container>
          <Row>
          <Col className="ml-auto mr-auto" md="12" sm="12" xs="12">
              <Row>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <h4 className="margin-0">Sobre nós</h4>
                    <p>Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto...
                      <a href="#!" onClick={(e) => e.preventDefault()}>
                          Leia mais
                        </a></p>
                  </div>
                </Col>
                <Col md="4" sm="3" xs="6">
                  <div className="links">
                  <h4 className="margin-0">Contatos</h4>
                    <ul className="uppercase-links stacked-links">

                    <a href="#!" onClick={(e) => e.preventDefault()}>
                    <li><i className="fa fa-phone" /> (11) 5959-5959 </li>
                        </a>
                        <a href="#!" onClick={(e) => e.preventDefault()}>
                        <li><i className="fa fa-whatsapp" /> (11) 95959-5959</li>
                        </a>
                        <a href="#!" onClick={(e) => e.preventDefault()}>
                        <li><i className="fa fa-map-marker" /> Rua das flores,000 - São Paulo SP</li>
                        </a>

                      
                      
                      
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                <div className="links">
                    <AddressMap/>
                  </div>
                </Col>
                <Col md="2" xs="6">
                  <div className="social-area">
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="facebook"
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="instagram"
                    >
                      <i className="fa fa-instagram" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="whatsapp"
                    >
                      <i className="fa fa-whatsapp" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="copyright">
                <div className="pull-left">
                  © {new Date().getFullYear()} Todos os direitos reservados <a href="#!" onClick={(e) => e.preventDefault()}>
                    WebMail
                        </a>
                </div>
                <div className="links pull-right">
                <a 
            href="https://criaziweb.com"
            target="_blank"
            className="mr-1" rel="noreferrer"
            >
              <img
                        alt="..."
                        src={
                          require("assets/img/criazi-footer.png")
                            .default
                        } width="120px"
                      /></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        </footer>
        </>
    );
}
export default FooterPadrao;
