/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import OneHeader from "../components/Headers/header";
import FooterPadrao from "components/Footers/FooterPadrao";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <MultiDropdownNavbar />
      <OneHeader/>
      <div className="wrapper">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Metodologia de ensino</h2>
                <h5>
                  Isso conta algumas informações da escola Isso conta algumas informações da escola Isso conta algumas informações da escola Isso conta algumas informações da escola Isso conta algumas informações da escola Isso conta algumas informações da escola
                </h5>
                <br />
                <Button
                  className="btn-fill btn-round"
                  color="danger"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Conheça sobre nós
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-palette" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Informação</h4>
                    <p className="description">
                    Texto Texto Texto Texto Texto Texto Texto Texto 
                    Texto Texto Texto Texto Texto Texto Texto Texto 
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Veja mais
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Informação</h4>
                    <p>
                    Texto Texto Texto Texto Texto Texto Texto Texto 
                    Texto Texto Texto Texto Texto Texto Texto Texto
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Veja mais
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Infromação</h4>
                    <p>
                    Texto Texto Texto Texto Texto Texto Texto Texto 
                    Texto Texto Texto Texto Texto Texto Texto Texto
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Veja mais
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Informação</h4>
                    <p>
                    Texto Texto Texto Texto Texto Texto Texto Texto 
                    Texto Texto Texto Texto Texto Texto Texto Texto
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Veja mais
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        

          <Container>
            
          <h3 className="more-info ">Quer saber sobre nosso sistema de ensino?</h3>
          <br/>
            <Row className="coloured-cards">
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="orange"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">Texto</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Texto
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="green"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">Texto</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Texto
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="yellow"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">Texto</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Texto
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      Texto Texto Texto Texto Texto Texto Texto 
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
            </Row>
          </Container>
      </div>
      <FooterPadrao/>
 

    </>
  );
}

export default LandingPage;
