/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  Todos os Direitos Reservados
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    className="mr-1"
                  >
                    WebMail
                  </a>
                </li>
                
              </ul>
            </nav>
            <div className="credits ml-auto">
            <a 
            href="https://criaziweb.com/pt-BR/"
            target="_blank"
            className="mr-1"
            >
              <img
                        alt="..."
                        src={
                          require("assets/img/criazi-footer.png")
                            .default
                        } width="120px"
                      /></a>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
